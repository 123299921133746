<template>
    <div class="dashboard">
        <div class="container-fluid">
            <div class="content-area">
                <div class="dashboard-content">
                    <div class="dashboard-header clearfix">
                        <div class="row">
                            <div class="col-md-6 col-sm-12">
                                <h4>العقارات الخاصة</h4>
                            </div>
                            <div class="col-md-6 col-sm-12">
                                <div class="breadcrumb-nav">
                                    <ul>
                                        <li>
                                            <router-link to="/my"
                                                >الرئيسية</router-link
                                            >
                                        </li>
                                        <li class="active">العقارات الخاصة</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="column col-lg-12">
                            <div class="properties-box">
                                <div class="title">
                                    <h3>قائمة العقارات الخاصة</h3>
                                </div>
                                <div class="inner-container">
                                    <template v-if="properties.length > 0">
                                        <!-- Property Block -->
                                        <div
                                            v-for="(
                                                property, index
                                            ) in properties"
                                            :key="property.id"
                                            class="property-block"
                                        >
                                            <div class="inner-box clearfix">
                                                <div class="image-box">
                                                    <figure class="image">
                                                        <img
                                                            :src="
                                                                property.image_url
                                                            "
                                                            alt=""
                                                        />
                                                    </figure>
                                                    <ul class="info clearfix">
                                                        <li class="text-left">
                                                            <div
                                                                class="rating text-info"
                                                            >
                                                                <span
                                                                    :class="
                                                                        property.average_overall_rating >=
                                                                        1
                                                                            ? 'la la-star'
                                                                            : 'la la-star-o'
                                                                    "
                                                                ></span>
                                                                <span
                                                                    :class="
                                                                        property.average_overall_rating >=
                                                                        2
                                                                            ? 'la la-star'
                                                                            : 'la la-star-o'
                                                                    "
                                                                ></span>
                                                                <span
                                                                    :class="
                                                                        property.average_overall_rating >=
                                                                        3
                                                                            ? 'la la-star'
                                                                            : 'la la-star-o'
                                                                    "
                                                                ></span>
                                                                <span
                                                                    :class="
                                                                        property.average_overall_rating >=
                                                                        4
                                                                            ? 'la la-star'
                                                                            : 'la la-star-o'
                                                                    "
                                                                ></span>
                                                                <span
                                                                    :class="
                                                                        property.average_overall_rating >=
                                                                        5
                                                                            ? 'la la-star'
                                                                            : 'la la-star-o'
                                                                    "
                                                                ></span>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div class="content-box">
                                                    <h3>
                                                        {{ property.title }}
                                                    </h3>
                                                    <div class="location">
                                                        <i
                                                            class="la la-map-marker"
                                                        ></i>
                                                        {{ property.address }}
                                                    </div>
                                                    <ul
                                                        class="property-info clearfix"
                                                    >
                                                        <li>
                                                            <i
                                                                class="flaticon-users"
                                                            ></i>
                                                            {{
                                                                property.guests
                                                            }}
                                                        </li>
                                                        <li>
                                                            <i
                                                                class="flaticon-bed"
                                                            ></i>
                                                            {{ property.beds }}
                                                        </li>
                                                        <li>
                                                            <i
                                                                class="la la-wifi"
                                                            ></i>
                                                            {{
                                                                property.wifi
                                                                    ? "متوفر"
                                                                    : "غير متوفر"
                                                            }}
                                                        </li>
                                                        <li>
                                                            <i
                                                                class="flaticon-bathtub"
                                                            ></i>
                                                            {{
                                                                property.bathrooms
                                                            }}
                                                        </li>
                                                    </ul>
                                                    <div class="price">
                                                        $ {{ property.price_1 }}
                                                    </div>
                                                </div>
                                                <div class="option-box">
                                                    <ul class="action-list">
                                                        <li>
                                                            <router-link
                                                                :to="
                                                                    'edit-property/' +
                                                                    property.id
                                                                "
                                                                ><i
                                                                    class="la la-edit"
                                                                ></i>
                                                                تعديل</router-link
                                                            >
                                                        </li>
                                                        <li>
                                                            <a
                                                                v-if="
                                                                    property.show
                                                                "
                                                                href="#"
                                                                @click.prevent="
                                                                    togglePropertyShow(
                                                                        property.id,
                                                                        index
                                                                    )
                                                                "
                                                                ><i
                                                                    class="la la-eye-slash"
                                                                ></i>
                                                                إخفاء</a
                                                            >
                                                            <a
                                                                v-else
                                                                href="#"
                                                                @click.prevent="
                                                                    togglePropertyShow(
                                                                        property.id,
                                                                        index
                                                                    )
                                                                "
                                                                ><i
                                                                    class="la la-eye"
                                                                ></i>
                                                                إظهار</a
                                                            >
                                                        </li>
                                                        <li>
                                                            <a
                                                                href="#"
                                                                @click.prevent="
                                                                    deleteProperty(
                                                                        property.id,
                                                                        index
                                                                    )
                                                                "
                                                                ><i
                                                                    class="la la-trash-o"
                                                                ></i>
                                                                حذف</a
                                                            >
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                    <div class="text-center" v-else>
                                        لا توجد عقارات مضافة بعد
                                    </div>
                                </div>

                                <div class="text-center mt-2">
                                    <button
                                        v-if="more"
                                        @click="getProperties(currentPage + 1)"
                                        class="btn btn-oval btn-primary edit text-white"
                                    >
                                        <i
                                            class="fa fa-plus"
                                            aria-hidden="true"
                                        ></i>
                                        المزيد
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "my.properties",
    data() {
        return {
            currentPage: 1,
            more: false,
            properties: [],
        };
    },
    mounted() {
        this.getProperties();
    },
    methods: {
        getProperties(page = 1) {
            this.$axios.get("my/properties?page=" + page).then((res) => {
                this.currentPage = res.data.properties.current_page;
                this.more = !!res.data.properties.next_page_url;
                this.properties = this.properties.concat(
                    res.data.properties.data
                );
            });
        },
        togglePropertyShow(id, index) {
            this.$axios.patch("/my/toggle-property-show/" + id).then(() => {
                this.properties[index].show = !this.properties[index].show;
            });
        },
        deleteProperty(id, index) {
            if (
                confirm(
                    "سيتم حذف الاعلان نهائيا بكل مشتملاته ولا يمكن الرجوع فى هذه الخطوة .. هل أنت متأكد من الحذف ؟"
                )
            ) {
                this.$axios.delete(`/my/properties/${id}`).then(() => {
                    this.properties.splice(index, 1);
                });
            }
        },
    },
};
</script>

<style scoped>
.property-block .image-box .info {
    bottom: 0;
    direction: ltr;
}
</style>